import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import Plyr from 'plyr/dist/plyr.min.js';
import 'slick-carousel/slick/slick';

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faCircle } from "@fortawesome/free-solid-svg-icons/faCircle";
import { faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faWordpressSimple } from "@fortawesome/free-brands-svg-icons/faWordpressSimple";

import './sass/main.scss'

(function( $ ) {
    // Use $ as usual here, e.g.:
    $(".slider-wrapper").slick({
        fade: true,
        autoplay: true,
        speed: 300,
        autoplaySpeed: 3000,
        arrows: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        swipe: false,
        touchMove: false
    });

    $(".client-slider").slick({
        arrows: true,
        lazyLoad: 'ondemand',
        autoplay: true,
        speed: 300,
        autoplaySpeed: 5000,
        arrows: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        prevArrow: ".client-slider-control-prev",
        nextArrow: ".client-slider-control-next"
    });
})( jQuery );

$(function() {
    return $(".carousel.lazy").on("slide.bs.carousel", function(ev) {
      var lazy;
      lazy = $(ev.relatedTarget).find("img[data-src]");
      lazy.attr("src", lazy.data('src'));
      lazy.removeAttr("data-src");
    });
  });

fetch("https://public-api.wordpress.com/rest/v1.1/sites/simulyve.international/posts?number=3&fields=title,URL")
.then(response => response.json())
.then(data => {
    if (data && data.posts) {
        for(var post of data.posts) {
            var blogSnippetNode = document.createElement("div");
            blogSnippetNode.classList.add("blog-post-snippet");

            var linkContainerNode = document.createElement("a");
            linkContainerNode.href = post.URL;
            linkContainerNode.target = "_blank";
            linkContainerNode.rel = "noopener";
    
            var titleNode = document.createElement("p");
            titleNode.innerText = post.title;
    
            var chevronNode = document.createElement("i");
            chevronNode.classList.add("fa", "fa-chevron-right");
    
            var learnMoreNode = document.createElement("div");
            learnMoreNode.classList.add("cta");
            learnMoreNode.innerText = "Learn More";
            learnMoreNode.appendChild(chevronNode);

            linkContainerNode.appendChild(titleNode);
            linkContainerNode.appendChild(learnMoreNode);
    
            blogSnippetNode.appendChild(linkContainerNode);            
            document.getElementById("blogPosts").appendChild(blogSnippetNode);
        }
    }
});

var currentlyPlayingVideo = null;
var youtubePlayerConfig = {
    fullscreen: { enabled: true, fallback: true, iosNative: true},
    muted: false,
    youtube: { 
        controls: 1,
        nocookie: false,
        rel: 0, 
        showinfo: 0, 
        iv_load_policy: 3, 
        modestbranding: 0
    },
    controls: []
}

function pauseOutOfFrameVideo() {
    if (currentlyPlayingVideo && !currentlyPlayingVideo.fullscreen.active) {
        var navBarHeight = document.getElementById('navbar').offsetHeight;
        var video = currentlyPlayingVideo.elements.container;
        var x = 0,
            y = 0,
            w = video.offsetWidth,
            h = video.offsetHeight,
            r, //right
            b, //bottom
            visibleX, visibleY, visible,
            parent;
    
        parent = video;
        while (parent && parent !== document.body) {
            x += parent.offsetLeft;
            y += parent.offsetTop;
            parent = parent.offsetParent;
        }
    
        r = x + w;
        b = y + h;
    
        visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));
        visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset))-navBarHeight;
    
        visible = visibleX * visibleY / (w * h);
    
        if (visible <= 0) {
            if (!currentlyPlayingVideo.paused) {
                currentlyPlayingVideo.pause();
                currentlyPlayingVideo = null;
            }
        } 
    }
}

const simuLyveCommercialTileContainer = document.querySelector("#simulyveCommercial");
const simuLyveCommercialTile = document.querySelector("#simulyveCommercialTile");
const simuLyveCommercialPlayer = new Plyr('#simulyveCommercialVideo', youtubePlayerConfig);
window.playCommercialVideo = function() {
    simuLyveCommercialTileContainer.classList.add("playing");
    simuLyveCommercialTile.classList.add("playing");
    simuLyveCommercialPlayer.currentTime = 0;
    simuLyveCommercialPlayer.play();
    simuLyveCommercialPlayer.muted = false;
}

const modelTileContainer = document.querySelector("#simuLyveModel");
const modelTile = document.querySelector("#modelVideoTile");
const modelVideoPlayer = new Plyr('#modelVideo', youtubePlayerConfig);
window.playModelVideo = function() {
    modelTileContainer.classList.add("playing");
    modelTile.classList.add("playing");
    modelVideoPlayer.currentTime = 0;
    modelVideoPlayer.play();
    modelVideoPlayer.muted = false;
}

const magicTileContainer = document.querySelector("#howTheMagicIsCreated");
const magicTile = document.querySelector("#magicVideoTile");
const magicVideoPlayer = new Plyr('#magicVideo', youtubePlayerConfig);
window.playMagicVideo = function() {
    magicTileContainer.classList.add("playing");
    magicTile.classList.add("playing");
    magicVideoPlayer.currentTime = 0;
    magicVideoPlayer.play();
    magicVideoPlayer.muted = false;
}

const simulyveOnDemandVideoTile = document.querySelector("#simulyveOnDemandVideoTile");
const simulyveOnDemandVideoPlayer = new Plyr('#simulyveOnDemandVideoPlayer', youtubePlayerConfig);
window.playSimulyveOnDemandVideo = function() {
    simulyveOnDemandVideoTile.classList.add("playing");
    simulyveOnDemandVideoPlayer.play();
    simulyveOnDemandVideoPlayer.muted = false;
}

const cannesPlayerTileContainer = document.querySelector("#cannesVideoContainer");
const cannesPlayerTile = document.querySelector("#cannesVideoTile");
const cannesPlayer = new Plyr('#cannesPlayer', youtubePlayerConfig);
window.playCannesVideo = function() {
    cannesPlayerTileContainer.classList.add("playing");
    cannesPlayerTile.classList.add("playing");
    cannesPlayer.currentTime = 0;
    cannesPlayer.play();
    cannesPlayer.muted = false;
}

const lookingInwardPlayerTileContainer = document.querySelector("#lookingInwardVideoContainer");
const lookingInwardPlayerTile = document.querySelector("#lookingInwardVideoTile");
const lookingInwardPlayer = new Plyr('#lookingInwardPlayer', youtubePlayerConfig);
window.playLookingInwardVideo = function() {
    lookingInwardPlayerTileContainer.classList.add("playing");
    lookingInwardPlayerTile.classList.add("playing");
    lookingInwardPlayer.currentTime = 0;
    lookingInwardPlayer.play();
    lookingInwardPlayer.muted = false;
}


const simulyveCommercialPlayerTileContainer = document.querySelector("#simulyveCommercialVideoContainer");
const simulyveCommercialPlayerTile = document.querySelector("#simulyveCommercialVideoTile");
const simulyveCommercialPlayer = new Plyr('#simulyveCommercialPlayer', youtubePlayerConfig);
window.playSimulyveCommercialVideo = function() {
    simulyveCommercialPlayerTileContainer.classList.add("playing");
    simulyveCommercialPlayerTile.classList.add("playing");
    simulyveCommercialPlayer.currentTime = 0;
    simulyveCommercialPlayer.play();
    simulyveCommercialPlayer.muted = false;
}


const arcturusTileContainer = document.querySelector("#arcturusVideoContainer");
const arcturusPlayerTile = document.querySelector("#arcturusVideoTile");
const arcturusPlayer = new Plyr('#arcturusPlayer', youtubePlayerConfig);
window.playArcturusVideo = function() {
    arcturusTileContainer.classList.add("playing");
    arcturusPlayerTile.classList.add("playing");
    arcturusPlayer.currentTime = 0;
    arcturusPlayer.play();
    arcturusPlayer.muted = false;
}

const prTileContainer = document.querySelector("#prVideoContainer");
const prPlayerTile = document.querySelector("#prVideoTile");
const prPlayer = new Plyr('#prPlayer', youtubePlayerConfig);
window.playPrVideo = function() {
    prTileContainer.classList.add("playing");
    prPlayerTile.classList.add("playing");
    prPlayer.currentTime = 0;
    prPlayer.play();
    prPlayer.muted = false;
}

const meetingTileContainer = document.querySelector("#meetingVideoContainer");
const meetingPlayerTile = document.querySelector("#meetingVideoTile");
const meetingPlayer = new Plyr('#meetingPlayer', youtubePlayerConfig);
window.playMeetingVideo = function() {
    meetingTileContainer.classList.add("playing");
    meetingPlayerTile.classList.add("playing");
    meetingPlayer.currentTime = 0;
    meetingPlayer.play();
    meetingPlayer.muted = false;
}

const doSomethingTileContainer = document.querySelector("#doSomethingVideoContainer");
const doSomethingPlayerTile = document.querySelector("#doSomethingVideoTile");
const doSomethingPlayer = new Plyr('#doSomethingPlayer', youtubePlayerConfig);
window.playDoSomethingVideo = function() {
    doSomethingTileContainer.classList.add("playing");
    doSomethingPlayerTile.classList.add("playing");
    doSomethingPlayer.currentTime = 0;
    doSomethingPlayer.play();
    doSomethingPlayer.muted = false;
}

const translationTileContainer = document.querySelector("#translationVideoContainer");
const translationPlayerTile = document.querySelector("#translationVideoTile");
const translationPlayer = new Plyr('#translationPlayer', youtubePlayerConfig);
window.playTranslationVideo = function() {
    translationTileContainer.classList.add("playing");
    translationPlayerTile.classList.add("playing");
    translationPlayer.currentTime = 0;
    translationPlayer.play();
    translationPlayer.muted = false;
}


window.addEventListener('scroll', pauseOutOfFrameVideo, false);
window.addEventListener('resize', pauseOutOfFrameVideo, false);

// Forms
 window.submitNewsletterForm = function() {
    var frm = document.getElementById('newsletterForm');
    if (frm.checkValidity()) {
        frm.submit(); // Submit the form
        return false; // Prevent page refresh
    }
}

// Load icons
library.add(faBars, faPlay, faLinkedin, faFacebookF, faYoutube, faTwitter, faArrowLeft, faArrowRight, faCircle, faChevronRight, faWordpressSimple);
dom.watch(); 